import { getInstance } from './authWrapper'
import StringUtil from '@/lib/string-util'
import storage from '@/storage'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = async () => {
    if (authService.isAuthenticated) {
      if (to.name === 'MemberEntry') {
        const claims = await authService.getIdTokenClaims().catch(e => {
          console.log('get id token error ', e)
        })
        if (!claims) {
          // 手でlocalStorageを削除すると戻れないのでログインするように設定
          authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
        }
        const memberCode = (claims['https://member.sapporo.coop/app_metadata'] || {})['memberCode']
        // console.log('memberCode', memberCode)
        if (memberCode) {
          const member12 = memberCode + '1000'
          const memberCardId = member12 + StringUtil.calcCheckDigit2(member12)

          storage.setCompMemberCode(memberCardId)
          
          // 共済サイトから遷移且つ組合員登録済の場合は、メッセージを表示してコープさっぽろのページを表示する
          if (storage.getCoopKyosaiMode()) {
            // セッションストレージから警告表示フラグを取得
            const alertShown = sessionStorage.getItem('kyosaiAlertShown');
            if (!alertShown) {
                // 警告がまだ表示されていない場合
                alert('既に組合員加入済のため当画面は閉じて頂き、お見積り保存時にお送りしているメールから組合加入手続きがお済みの方として、再開ください。');
                // 警告を表示したフラグをセット
                sessionStorage.setItem('kyosaiAlertShown', 'true');
            }
            window.location = 'https://www.sapporo.coop/'
          }else{
            authService.logout({ returnTo: window.location.origin + '/complete' })
          }
        }
      }
      return next()
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
