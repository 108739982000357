<template>
  <div class="mb-6">
    <v-row>
      <v-col class="pt-6">
        <common-input-title :title="inputTitle" :requiredFlag="requiredFlag" :caption="caption" />
      </v-col>
    </v-row>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="selectedDate"
          :placeholder="placeholder"
          prepend-icon="mdi-calendar"
          readonly
          v-on="on"
          outlined
          hide-details
          background-color="memberid-white"
          color="memberid-primary"
          :disabled="isDisabled"
          :error="hasError"
          @blur="$emit('blur', $event)"
          @click="clickPickerTextField()"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        locale="jp-ja"
        v-model="selectedDate"
        :day-format="date => new Date(date).getDate()"
        :max="maxDate"
        :active-picker.sync="activePicker"
        :picker-date="pickerDate"
        :min="minDate"
        @change="saveDate"
        color="memberid-primary"
      ></v-date-picker>
    </v-menu>
    <v-row class="mt-2 ml-1">
      <error-messages :isShow="hasError" :messages="errorMessages"></error-messages>
    </v-row>
  </div>
</template>

<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessages from '@/components/ErrorMessageListComponent'
export default {
  components: { CommonInputTitle, ErrorMessages },

  data() {
    return {
      menu: false,
      pickerDate: this.defaultDateParam
        ? this.defaultDateParam.toISOString().substring(0, 10)
        : new Date().toISOString().substring(0, 10),
        activePicker: '',
    }
  },
  props: {
    placeholder: { type: String },
    inputTitle: { type: String },
    caption: { type: String },
    requiredFlag: { type: Boolean },
    inputDate: { type: String },
    maxDateParam: { type: Date },
    minDateParam: { type: Date },
    defaultDateParam: { type: Date },
    errorMessages: { type: Array },
    isDisabled: { type: Boolean, default: false },
  },
  methods: {
    resetDate() {
      this.$nextTick(function() {
        this.selectedDate = ''
        if (this.defaultDateParam) {
          this.pickerDate = this.defaultDateParam.toISOString().substring(0, 10)
        } else if (this.maxDateParam) {
          this.pickerDate = this.maxDateParam.toISOString().substring(0, 10)
        } else {
          this.pickerDate = new Date().toISOString().substring(0, 10)
        }
      })
    },
    clickPickerTextField() {
      if (!this.selectedDate) {
        this.resetDate()
      }
    },
    saveDate(date) {
      this.$refs.menu.save(date)
      // 再入力に備えて、入力が終わったら同期する
      this.pickerDate = date
    },
  },
  computed: {
    hasError: function() {
      return typeof this.errorMessages !== 'undefined' && this.errorMessages.length > 0
    },
    selectedDate: {
      get() {
        return this.inputDate
      },
      set(value) {
        this.$emit('update:inputDate', value)
      },
    },
    maxDate() {
      if (this.maxDateParam) {
        return this.maxDateParam.toISOString()
      }
      return new Date().toISOString()
    },
    minDate() {
      if (this.minDateParam) {
        return this.minDateParam.toISOString()
      }
      return null
    },
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => (
            // 年から選ぶようにする
            (
              this.activePicker = "YEAR"
            ),
            // 選び始めたら初期化
            (this.pickerDate = null)
          )
        )
    },
    maxDate() {
      this.resetDate()
    },
    minDate() {
      this.resetDate()
    },
  },
}
</script>
