import { helpers } from 'vuelidate/lib/validators'
import Encoding from 'encoding-japanese'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import StringUtil from '@/lib/string-util'

export const checkZenSpace = val => (val || '').indexOf('　') < 0
export const length50 = val => (val || '').length <= 50
export const length60 = val => (val || '').length <= 60
export const zenkaku = helpers.regex('zenkaku', /^[^ -~｡-ﾟ]*$/)
export const zenkakuWithSpace = helpers.regex('zenkakuWithSpace', /^[^-~｡-ﾟ]*$/)
export const hanKana = helpers.regex('hanKana', /^[ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､ ]*$/)
export const zenKana = helpers.regex('zenKana', /^[ァ-ヶー]+$/)
export const checkShiftJis = str => {
  const sjisString = Encoding.convert(str, {
    to: 'SJIS',
    from: 'UNICODE',
    type:'string'
  });
  const utf8String = Encoding.convert(sjisString, {
    to: 'UNICODE',
    from: 'SJIS',
    type:'string'
  });


  return utf8String === str
}

/***************************************************/
//使用許可文字確認のパターン(pattern)

// 0：全角文字はJISコードの以下に対応する文字のみ使用可能とする
//    記号は「ゝ、ゞ、々、ー（長音）、全角スペース」のみ許可

// 1：記号は「ー（長音）、全角スペース」のみ許可

// 2：全角文字はJISコードの以下に対応する文字のみ使用可能とする
//    記号は「ゝ、ゞ、々、ー―－‐（長音、ダッシュ、マイナス、ハイフン）、全角スペース」のみ許可

// 3：全角文字はJISコードの以下に対応する文字のみ使用可能とする
//    記号は「ゝ、ゞ、々」のみ許可

/***************************************************/
export const checkJISLevel1and2 = (str,pattern) => {
  // 使用可能なJISコード

  const level1and2Ranges = [
    [0x8140, 0x9FFC], // (各種記号、英数字、かな)
    [0x889F, 0xE4FC], // 16区～47区（JIS第一水準漢字）
    [0xE540, 0xFCFC]  // 48区～84区（JIS第二水準漢字）
  ];

  // 入力禁止文字/記号を定義
 
  const prohibitedSymbols = [
    '（', '）', '［', '］', '｛', '｝', '＜', '＞', '＋', '＝', '≦', '≧', '≪', '≫', 
    '∈', '∋', '⊆', '⊇', '⊂', '⊃', '＄', '￥', '￠', '￡', '￣', '′', '″', '‰', '℃', 
    '㎜', '㎝', '㎞', '㎎', '㎏', '％', '‰', '㍉', '㌔', '㌢', '㍍', '㌘', '㌧', '㌃', '㌶', 
    '㍑', '㍗', '㌍', '㌦', '㌣', '㌫', '㍊', '㌻', '㎜', '㎝', '㎞', '㎎', '㎏', '㏄', '㎡', 
    '←', '→', '↑', '↓', '⇒', '⇔', '§', '¶', '†', '‡', '♪', '☆', '★', '○', '●', 
    '◎', '◇', '◆', '□', '■', '▲', '▼', '★', '☆', '＆', '＠', '＃', '＊', '＾', '＿', 
    '～', '｜', '￥', '／', '＼', '∥', '§', '¶', '†', '‡', '♯', '♭', '♪', '◯', '◎', 
    '▲', '△', '▼', '▽', '〝', '〟', '№', '㏍', '℡', 
    'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 
    'ρ', 'σ', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω', 
    'Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ', 'Ν', 'Ξ', 'Ο', 'Π', 
    'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω', 
    'Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', 'Ⅵ', 'Ⅶ', 'Ⅷ', 'Ⅸ', 'Ⅹ', 
    'ⅰ', 'ⅱ', 'ⅲ', 'ⅳ', 'ⅴ', 'ⅵ', 'ⅶ', 'ⅷ', 'ⅸ', 'ⅹ', 
    '〒', '※', '〆', '〇', '△', '▽', '♯', '♭', '∠', '⊥', '⌒', '∂', '∃', '∀', '∈', '∋', 
    '∑', '∮', '∫', '∬', '∇', '〓', '∝', '∟', '⊿', '≡', '≒', '≪', '≫', '√', '∽', '∂', 
    '∃', '∠', '∟', '⊿', '⌒', '∈', '∋', '∩', '∪', '⊥', '⊆', '⊇', '⊂', '⊃', 
    '～', '・', '：', '；', '／', '＼', '｜', '＠', '＃', '＄', '％', '＆', 
    '＊', '＋', '＝', '？', '！', '＾', '＿', '｀', '｛', '｝', '｜', '￣', 
    '〃', '仝', 'ヽ', 'ヾ', 
    '①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', 
    '⑪', '⑫', '⑬', '⑭', '⑮', '⑯', '⑰', '⑱', '⑲', '⑳', 
    '㍻', '㈱', '㈲', '㈹', '㍾', '㍽', '㍼', 
    '　' // 全角スペース
];


  // パターンに応じて特殊禁止文字を定義
  const sp_prohibitedSymbols = {
    0: ['ゝ', 'ゞ', '々', '―', '‐', '－'],  // 5
    1: ['―', '‐', '－'],                   // 6
    2: ['ゝ', 'ゞ', '々'],                 // 7
    3: ['ー', '　', '―', '‐', '－'],       // 9
  };


  // 姓名カナ入力で先頭に来てはいけない文字を定義
  const smallKatakana = [
    'ァ', 'ィ', 'ゥ', 'ェ', 'ォ',
    'ヵ', 'ヶ',
    'ッ',
    'ャ', 'ュ', 'ョ',
    'ヮ',
    'ヲ'
  ];

  // 入力禁止文字/記号をShift-JISに変換
const prohibitedSymbolsSJIS = prohibitedSymbols.map(char => {
  const sjisArray = Encoding.convert(char, {
    to: 'SJIS',
    from: 'UNICODE',
    type: 'array'
  });
  
  // 1バイトまたは2バイトのコードを数値に変換
  if (sjisArray.length === 1) {
    return sjisArray[0];
  } else if (sjisArray.length === 2) {
    return (sjisArray[0] << 8) | sjisArray[1];
  } else {
    return null;
  }
});

// 特殊禁止文字をShift-JISに変換
const sp_prohibitedSymbolsSJIS = {};
for (const [key, value] of Object.entries(sp_prohibitedSymbols)) {
  sp_prohibitedSymbolsSJIS[key] = value.map(str => {
    const sjisArray = Encoding.convert(str, {
      to: 'SJIS',
      from: 'UNICODE',
      type: 'array'
    });
    
    // 1バイトまたは2バイトのコードを数値に変換
    if (sjisArray.length === 1) {
      return sjisArray[0];
    } else if (sjisArray.length === 2) {
      return (sjisArray[0] << 8) | sjisArray[1];
    } else {
      return null;
    }
  }).filter(code => code !== null); // null値を除外
}

  // 姓名カナ入力で先頭に来てはいけない文字をShift-JISに変換
  const smallKatakanaSJIS = smallKatakana.map(char => {
    const sjisArray = Encoding.convert(char, {
      to: 'SJIS',
      from: 'UNICODE',
      type: 'array'
    });
    
    // 1バイトまたは2バイトのコードを数値に変換
    if (sjisArray.length === 1) {
      return sjisArray[0];
    } else if (sjisArray.length === 2) {
      return (sjisArray[0] << 8) | sjisArray[1];
    } else {
      return null;
    }
  }).filter(code => code !== null); // null値を除外

  // 文字列をShift-JISに変換
  const sjisString = Encoding.convert(str, {
    to: 'SJIS',
    from: 'UNICODE',
    type: 'array'
  });

  let charCode;
  let i;
  let ret = true; // 文字列が有効かどうかを示すフラグ
  
  for (i = 0; i < sjisString.length; i++) {
    if (sjisString[i] > 0x80) {
      // 2バイト文字の場合
      charCode = (sjisString[i] << 8) | sjisString[i + 1];
      i++; // 次のバイトをスキップ
      // patternが0のときに先頭文字をチェック
      if (pattern === 0 && i === 1) {
          const isSmallKatakana = smallKatakanaSJIS.includes(charCode);
      if (isSmallKatakana) {
          console.log('先頭に小文字カナが使用されています');
          console.log(`Detected small katakana: ${String.fromCharCode(charCode)} (code: 0x${charCode.toString(16).padStart(4, '0')})`);
          ret = false;
          break;
      }
    }
    } else {
      // 1バイト文字の場合（ASCIIなど）
      charCode = sjisString[i];
    }
  
     // 文字コードが第一水準または第二水準の範囲内にあるかチェック
    const isInRange = level1and2Ranges.some(([start, end]) => 
      charCode >= start && charCode <= end
    );

    // 範囲外の場合は戻り値異常で終了させる
    if (!isInRange) {
      console.log('第一水準、第二水準の範囲外')
      console.log(charCode)
      ret = false
      break
    }

    // 入力禁止文字/記号に該当するかチェック
    const isProhibited = prohibitedSymbolsSJIS.some(prohibitedCode => {
      const isMatch = prohibitedCode === charCode;
      return isMatch
    });

    // 該当する場合は戻り値異常で終了させる
    if (isProhibited) {
      console.log('入力禁止文字検知')
      ret = false
      break 
    }


    // パターンに応じた特殊禁止文字のチェック
    if (sp_prohibitedSymbolsSJIS[pattern]) {
      const isSpProhibited = sp_prohibitedSymbolsSJIS[pattern].some(prohibitedCode => {
        const isMatch = prohibitedCode === charCode;
        return isMatch;
      });
    
      // 該当する場合は戻り値異常で終了させる
      if (isSpProhibited) {
        console.log('特殊禁止文字検知');
        ret = false
        break
      }
    }
  }

  return ret

};

export const isShowPhoneNumberError = val => {
  //＝＝＝＝＝＝
  // 関数定義
  //＝＝＝＝＝＝
  // 電話番号の有効性チェック関数
  const phoneNumberNoneProblem = function(val) {
    if (val) {
      // +81は日本の電話番号を示す
      const parsedPhoneNumber = parsePhoneNumberFromString('+81' + val, 'JP')
      return parsedPhoneNumber ? parsedPhoneNumber.isValid() : true
    } else {
      return true
    }
  }
  // ハイフン有無を確認する関数
  const isHaihun = RegExp('-')

  //＝＝＝＝＝＝
  // 関数呼び出し
  //＝＝＝＝＝＝

  // 番号の有効性チェック
  if (!phoneNumberNoneProblem(val)) {
    return false
  }

  // 有効な番号の場合整形
  const formatedPhoneNumber = StringUtil.formatPhoneNumber(val)

  // 整形後にハイフンが入ってない番号 ＝ 存在し得ない番号
  if (!isHaihun.test(formatedPhoneNumber)) {
    return false
  }
  return true
}
