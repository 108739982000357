import Config from '@/config'
import MemberidFrontendPlugin from '@/plugins/memberid-frontend'
import vuetify from '@/plugins/vuetify'
import storage from '@/storage'
import Vue from 'vue'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import { Auth0Plugin } from './auth'
import router from './router'

Vue.config.productionTip = false
Vue.use(MemberidFrontendPlugin)
Vue.use(Vuelidate)

/**
 * リクエストURLに営業担当者を示すパラメータspが含まれている場合、SessionStorageにキー sp として値を設定する
 * @param query router#beforeEachの引数のto.query
 */
function handleSalesPersonParameter(query) {
  if (query && query.sp) {
    storage.saveSalesPersonEmployeeCode(query.sp)
  }
}

/**
 * リクエストURLに共済サイトからの遷移を示すパラメータckが含まれている場合、SessionStorageにキー ck として値を設定する
 * @param query router#beforeEachの引数のto.query
 */
function handleCoopKyosaiParameter(query) {
  if (query && query.ck) {
    storage.saveCoopKyosaiMode(query.ck,query.pc,query.bi,query.ge)
  }
}

/**
 * routerの遷移時にQueryStringから値を取得する
 */
router.beforeEach((to, from, next) => {
  // パラメータから営業担当者コードを取得してSessionStorageに入れる
  handleSalesPersonParameter(to.query)
  // パラメータから共済サイト遷移状態を取得してSessionStorageに入れる
  handleCoopKyosaiParameter(to.query)
  
  next()
  
})

// Auth0をPluginとして読み込む
Vue.use(Auth0Plugin, {
  domain: Config.auth0Config.domain,
  clientId: Config.auth0Config.clientId,
  redirectUri: Config.auth0Config.redirectUri,
  audience: Config.auth0Config.audience,
  useRefreshTokens: true,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
