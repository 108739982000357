<template>
  <div>
    <v-row>
      <v-col class="pt-6">
        <common-input-title title="郵便番号" :requiredFlag="true" caption="※ハイフン無し、半角数字" />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col xs="12" cols="7" class="pt-0">
        <v-text-field
          v-model="zipCode"
          color="memberid-primary"
          background-color="memberid-white"
          outlined
          hide-details
          placeholder="0600000"
          :disabled="isDisabled"
          :error="$v.zipCode.$error"
          @blur="$v.zipCode.$touch()"
          @input="$v.zipCode.$touch()"
          @change="setErrorMessages()"
          @update:error="setErrorMessages()"
        ></v-text-field>
      </v-col>
      <v-col xs="12" cols="5" class="pt-0 pl-0">
        <v-btn
          id="custom-disabled-search"
          :disabled="$v.zipCode.$invalid"
          height="56"
          width="130"
          class="memberid-black-400 memberid-white--text subtitle-1"
          @click="searchAddress()"
        >
          住所を検索する
        </v-btn>
      </v-col>
    </v-row>
    <error-messages :isShow="$v.zipCode.$error" :messages="errorMessages"></error-messages>
  </div>
</template>
<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessages from '@/components/ErrorMessageListComponent'
import memberidApiClient from '@/api/memberid-api-client'
import AbstractApiRequest from '@/api/apimodel/abstract-api-request'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default {
  name: 'ZipCodeComponent',
  mixins: [validationMixin],
  components: { CommonInputTitle, ErrorMessages },
  data() {
    return {
      list: this.addressList,
      errorMessages: [],
    }
  },
  props: {
    value: { type: String },
    addressList: { type: Array },
    isDisabled: { type: Boolean, default: false },
  },
  validations: {
    zipCode: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
  },
  methods: {
    setErrorMessages() {
      this.errorMessages = []
      if (!this.$v.zipCode.$dirty) return this.errorMessages
      !this.$v.zipCode.required && this.errorMessages.push(`郵便番号をご入力ください。`)
      !this.$v.zipCode.numeric && this.errorMessages.push('半角数字でご入力ください。')
      !this.$v.zipCode.minLength && this.errorMessages.push('文字数が不足しています。')
      !this.$v.zipCode.maxLength && this.errorMessages.push('文字数が多いです。')
      return this.errorMessages
    },

    async searchAddress() {
      this.$v.zipCode.$touch()
      if (this.$v.zipCode.$invalid) {
        return
      }
      Vue.$startLoading()
      this.list = []
      let req = new AbstractApiRequest()
      await memberidApiClient
        .getAddressList(this.zipCode)
        .then(response => {
          const addresses = response.data.addresses
          if (!addresses) {
            this.list = []
          } else {
            addresses.forEach(obj => {
              this.list.push(obj.address)
            })
            this.addressDisabled = false
          }
        })
        .catch(error => {
          req.defaultErrorProcess(error)
          this.list = []
          Vue.$endLoading()
          throw error
        })
        .finally(() => {
          Vue.$endLoading()
        })
      this.$emit('update:addressList', this.list)
      this.$emit('update:value', this.value)
      this.$emit('custom', this.$event)
      Vue.$endLoading()
    },
  },
  computed: {
    zipCode: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>
