<template>
  <div class="pc-wrap memberid-signup-main">
    <v-container class="mt-1">
      <member-id-component :memberCode="memberCode" :fullName="fullName" :coopKyosaiMode="coopKyosaiMode" />
      <app-recommend-component />
      <v-row justify="center" class="mt-10 ">
        <h3 class="memberid-secondary--text title font-weight-bold">ご利用できるサービス</h3>
      </v-row>
      <v-row justify="center" class="mt-4">
        <link-banner-component
          v-for="item in commonServiceList"
          :key="item.title"
          :theme="themeSecondary"
          v-bind="item"
        />
      </v-row>
      <v-row justify="center" class="mt-10">
        <h3 class="memberid-primary--text title font-weight-bold">子育て応援サービス</h3>
      </v-row>
      <v-row justify="center" class="mt-4">
        <link-banner-component
          v-for="item in childSupportServiceList"
          :key="item.title"
          :theme="themePrimary"
          v-bind="item"
        />
      </v-row>
    </v-container>
    <rounded-button v-if="this.coopKyosaiMode === null" class="for-ga-return-todok" buttonText="コープさっぽろTOPに戻る" :nextPage="nextPage" />
  </div>
</template>
<script>
// import Vue from 'vue'
import childSupportServiceList from '@/assets/childSupportServiceList.json'
import commonServiceList from '@/assets/commonServiceList.json'
import AppRecommendComponent from '@/components/AppRecommendComponent'
import LinkBannerComponent from '@/components/LinkBannerComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import MemberIdComponent from '@/components/MemberIdComponent'
import CommonMixin from '@/components/mixins/common-mixin'
import Constants from '@/lib/constants'
import storage from '@/storage'

export default {
  name: 'MemberEntryComplete',
  components: {
    AppRecommendComponent,
    RoundedButton,
    LinkBannerComponent,
    MemberIdComponent,
  },
  mixins: [CommonMixin],
  data() {
    return {
      memberCode: '',
      fullName: '',
      coopKyosaiMode: '',
    }
  },
  mounted() {
    // セッション情報消す
    storage.allClear()
    this.memberCode = storage.getCompMemberCode()
    this.fullName = storage.getCompMemberName()
    this.coopKyosaiMode = storage.getCoopKyosaiMode()
  },
  methods: {
    nextPage() {
      window.location = 'https://www.sapporo.coop/'
    },
  },
  computed: {
    commonServiceList() {
      return commonServiceList
    },
    childSupportServiceList() {
      return childSupportServiceList
    },
    themePrimary() {
      return Constants.LINK_BANNER_THEMES.PRIMARY
    },
    themeSecondary() {
      return Constants.LINK_BANNER_THEMES.SECONDARY
    },
  },
}
</script>
