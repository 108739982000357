<template>
  <v-app :resize="onResize">
    <div class="memberid-header-position ma-2">
      <div v-if="!isLoginPage" class="memberid-header-size memberid-header-bottom">
        <v-toolbar-title style="" id="app-logo " class="caption d-flex justify-space-between">
          <div :ripple="{ center: true }">
            <img class="memberid-header-logo" src="./assets/logo.png" />
            {{ getEnvName }}
          </div>
          <h2 class="memberid-black-600--text text-center subtitle-1">{{ this.title }}</h2>
          <div></div>
        </v-toolbar-title>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
// import MemberEntrance from './views/MemberEntrance'
import Config from '@/config'
import storage from '@/storage'
import store from '@/store'

export default {
  name: 'App',
  components: {
    // MemberEntrance,
  },
  mounted() {
    const to = this.$route
    this.setMeta(to)
    const from = to.query.from || storage.getFrom()
    if (from) {
      store.dispatch('setFrom', from)
    }
  },
  data() {
    return {
      title: '',
      previousPage: null,
    }
  },
  methods: {
    init() {
      store.dispatch('initErrorMessages')
    },
    setMeta(to) {
      // タイトルを設定
      if (to.meta.title) {
        const setTitle = to.meta.title
        document.title = setTitle
        this.title = setTitle.slice(0, setTitle.indexOf('｜'))
      }

      // メタタグdescription設定
      if (to.meta.desc) {
        const setDesc = to.meta.desc
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }

      // メタタグkeywords設定
      if (to.meta.keywords) {
        const setKeywords = to.meta.keywords
        document.querySelector("meta[name='keywords']").setAttribute('content', setKeywords)
      }
    },

    onResize() {
      if (window.innerWidth < this.breakPoint) this.isMobile = true
      else this.isMobile = false
    },
  },
  computed: {
    getEnvName() {
      return Config.getEnvName
    },
    isLoginPage() {
      return this.$route.name === 'MemberEntrance'
    }
  },
  watch: {
    $route(to) {
      this.setMeta(to)
      // console.log(from)
    },
  },
}
</script>

<style>
.memberid-black-0-op50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.memberid-orange-op10 {
  background-color: rgba(239, 141, 24, 0.1) !important;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis-row2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.pc-wrap {
  max-width: 960px;
  margin: 0 auto;
}
.xs-wrap {
  max-width: 600px;
  margin: 0 auto;
}
.pc-left-content {
  max-width: 615px;
}
.pc-right-content {
  max-width: 330px;
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pc-wrap {
    width: 960px;
  }
  .xs-wrap {
    width: 600px;
  }
  .pc-left-content {
    width: 615px;
  }
  .pc-right-content {
    width: 330px;
  }
}

.v-btn--icon.v-size--small {
  height: 14px !important;
  width: 14px !important;
}
.v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  height: 8px !important;
  font-size: 8px !important;
  width: 8px !important;
}
.v-btn--fab.v-size--default {
  height: 66px;
  width: 66px;
}
.v-input__slot {
  margin-bottom: 0 !important;
}
.v-text-field__details {
  display: none;
}
.v-btn--disabled {
  /* トドック / Gray / 300 */
  background-color: #bdc6c0 !important;
  color: #fff !important;
}
#custom-disabled-next.v-btn--disabled {
  /* トドック / Gray / 300 */
  background-color: #bdc6c0 !important;
}
#custom-disabled-search.v-btn--disabled {
  /* 組合員ID / black / 400 */
  background-color: #77837B !important;
}
#custom-disabled-search.v-btn--disabled .v-icon {
  color: #fff !important;
}
#custom-disabled-memberInfo.v-btn--disabled {
  /* 組合員ID / black / 400 */
  background-color: #77837B !important;
}
#custom-disabled-memberInfo.v-btn--disabled .v-icon {
  color: #fff !important;
}
#app-logo {
  cursor: pointer;
}
a {
  text-decoration: none;
}
#outlined-brown {
  border: thin solid #5c370c !important;
}
.v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#carousel .v-window__prev .theme--light.v-btn.v-btn--icon,
#carousel .v-window__next .theme--light.v-btn.v-btn--icon {
  color: #fff !important;
}
#carousel .v-window__prev .v-btn--icon.v-size--default,
#carousel .v-window__next .v-btn--icon.v-size--default {
  height: 24px;
  width: 24px;
}
#carousel .v-window__prev .v-btn--icon.v-size--default .v-icon,
#carousel .v-window__next .v-btn--icon.v-size--default .v-icon {
  height: 18px;
  font-size: 28px !important;
  width: 18px;
}
#tabs-non-arrow .v-slide-group__prev,
#tabs-non-arrow .v-slide-group__next {
  display: none;
}
#amount-width {
  width: 100px !important;
}
.v-snack--absolute {
  position: fixed !important;
}

.planning-week-header-pc-wrap {
  width: 500px;
}

.memberid-signup-main {
  margin-top: 0px;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}
.memberid-signup-main .year-select-box {
  width: 130px;
}
.memberid-signup-main .month-and-date-select-box {
  width: 80px;
}
.memberid-signup-main .v-text-field__details {
  margin-top: 4px;
  display: flex !important;
}
.memberid-signup-main .v-messages {
  margin-top: 4px;
}
.memberid-signup-main .v-input--is-disabled .v-input__slot {
  background-color: #f3f3f3 !important;
}
.memberid-signup-main .text-active.theme--light.v-file-input .v-file-input__text--placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}
.memberid-signup-main .movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}
.memberid-signup-main .movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.input-order-number-pc-position {
  right: 15% !important;
  bottom: 15% !important;
}
</style>
