<template>
  <v-container class="mt-1">
    <v-layout justify-center class="memberid-primary--text font-weight-bold">
      ご加入ありがとうございます！
    </v-layout>
    <v-layout v-if="showName" justify-center> {{ this.fullName }}様の組合員番号は </v-layout>
    <v-layout v-else justify-center> 組合員番号は </v-layout>
    <v-layout class="rounded-lg memberid-black-100 pa-4 my-3 text-h6 font-weight-bold" justify-space-between>
      <v-layout justify-center>{{ this.memberCode }}</v-layout>
      <v-btn icon @click="copyToClipboard()" v-if="canCopyToClipboard">
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-layout>
    <v-sheet class="rounded-lg red lighten-5 pa-4">
      <v-layout justify-center class="memberid-red--text text-h5 font-weight-bold pa-1">
        メモをとるか、画面キャプチャで保存をお願いします！
      </v-layout>
      <v-layout justify-center class="memberid-red--text pa-1">
        上記の組合員番号は、アプリのご利用に必要になります。
      </v-layout>
      <v-layout justify-end>
        <v-img :src="require('@/assets/todok-backgroundimage.png')" class="mt-n8 mb-n4 mr-n4" max-width="60" />
      </v-layout>
    </v-sheet>
    <v-layout justify-center v-if="this.coopKyosaiMode != null && this.coopKyosaiMode !== ''" class="pa-5">
      <v-btn height="60"
        width="40%"
        color="#000080"
        class="rounded-pill memberid-white--text font-weight-bold subtitle-1 for-ga-app-detail-btn "
        @click="postMemberInfo()">
        共済加入の手続きを進める
      </v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import memberidApiClient from '@/api/memberid-api-client'
import FormPost from '@/lib/form-post'
import AbstractApiRequest from '@/api/apimodel/abstract-api-request'
import PostMemberInfoRequest from '@/api/apimodel/postmemberinfo-request'
import Vue from 'vue'
export default {
  name: 'MemberIdComponent',
  props: {
    fullName: { type: String },
    memberCode: { type: String, required: true },
    coopKyosaiMode: { type: String },
  },
  mounted() {},
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.memberCode)
    },
    async postMemberInfo() {
      Vue.$startLoading()
      let req = new AbstractApiRequest()
      const postmemberinfoRequest = new PostMemberInfoRequest()
      postmemberinfoRequest.memberCode = this.memberCode
      postmemberinfoRequest.onetimeToken = this.coopKyosaiMode
      await memberidApiClient
        .postMemberInfo(postmemberinfoRequest)
        .then(response => {
          console.log('リダイレクトURL受け取り')
          const data = JSON.parse(response.data.body)
          const url = data.url
          console.log(url)
          const params = data.params
          console.log(params)
          FormPost.postCoopKyosai(url, params)
        })
        .catch(error => {
          console.log('組合員加入情報受け渡しエラー')
          req.defaultErrorProcess(error)
          Vue.$endLoading()
          throw error
        })
        .finally(() => {
          Vue.$endLoading()
        })
      Vue.$endLoading()
    }
  },
  computed: {
    canCopyToClipboard: function() {
      // clipboard の実装度合いはブラウザ毎に異なるので全てに対応すると複数実装が必要になる
      // ライブラリの依存関係を増やさない、ユーザーの利便性の確保
      // 双方の両立をねらって今回は navigator.clipboard.writeText にのみ対応させる
      try {
        const f = navigator.clipboard.writeText
        return f instanceof Function
      } catch(e) {
        return false;
      }
    },
    showName: function() {
      return this.fullName !== undefined && this.fullName !== null && this.fullName.length > 0
    },
  },
}
</script>
